















import { defineComponent, computed, reactive } from '@vue/composition-api';
import ApexCharts from 'apexcharts';
import merge from 'lodash/merge';
import forEach from 'lodash/forEach';
import DataSchema from '../@types/data-schema';

export default defineComponent({
  props: {
    data: {
      required: true,
      type: Object as () => DataSchema,
    },
  },
  setup(props) {
    const {
      id, title, chartConfigs,
    } = props.data;

    forEach(chartConfigs, (Obj, index) => {
      const defaultConfig = {
        chart: {
          type: 'line',
          height: 500,
        },
        yaxis: {
          labels: {
            minWidth: 40,
          },
        },
        dataLabels: {
          enabled: false,
        },
      };
      merge(chartConfigs, defaultConfig);
    });

    return {
      title,
      chartConfigs,
      id,
    };
  },
  mounted() {
    const chart = new ApexCharts(document.getElementById(this.id), this.chartConfigs);
    chart.render();
  },
});
